import React, { useRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import linkedIn from '../images/linkedin.png';
import whatsApp from '../images/whatsapp.png';
import logo from '../images/logo.png';

export function Index() {
  const [open, setOpen] = React.useState(false);

  function copyToClipboard(e) {
    const text = e.currentTarget.innerText;
    navigator.clipboard.writeText(text);
    setOpen(true);
  }

  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <img src={logo} width="300" alt="JETiCO" className="mb-10" />
        <div className="text-center">
          <p>
            <a href="mailto:info@jetico.eu">info@jetico.eu</a>
          </p>
          <p>
            JETiCO BV -{' '}
            <span onClick={copyToClipboard} className="cursor-pointer">
              BE0735.529.323
            </span>{' '}
            - RPR GENT
          </p>
          <p>Gloxiniastraat 11 - 9940 Evergem - Belgium</p>
        </div>
        <div className="flex justify-center mt-10">
          <a
            href="https://www.linkedin.com/in/jan-tilburgh-049a1b9/"
            target="_blank"
          >
            <img src={linkedIn} alt="LinkedIn" width="30"></img>
          </a>
          <a href="https://wa.me/+32479231485" target="_blank">
            <img src={whatsApp} alt="WhatsApp" width="30"></img>
          </a>
        </div>
      </div>

      <Snackbar
        autoHideDuration={1000}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        message="Copied to clipboard"
      />
    </div>
  );
}

export default Index;
